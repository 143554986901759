<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-card elevation="1">
    <!-- <table-toolbar /> -->
    <template v-if="showPlayer">
      <v-expand-transition>
        <record-player @closed="closedPlayer" v-if="showPlayer" :file="file" />
      </v-expand-transition>

      <v-divider v-if="showPlayer" />
    </template>
    <v-toolbar flat>
      <v-row no-gutters class="mt-4">
        <v-col cols="12" sm="2" class="pt-2">
          <v-switch label="With Check" inset v-model="onlyWithCheck"></v-switch>
        </v-col>
        <v-col>
          <v-text-field
            :rules="[rules.phone]"
            prepend-inner-icon="mdi-numeric-positive-1"
            outlined
            v-model="filternumber"
            dense
            clearable
            label="Search by Number"
            :disabled="filterpatient != null || filteremployee != null"
            @click:clear="clearFilters"
          ></v-text-field>
        </v-col>
        <v-col>
          <filter-by-clients
            @selectC="filteringPatient($event)"
            class="ml-1"
            :disabled="
              filteremployee != null ||
              (filternumber != null && filternumber != null)
            "
          />
        </v-col>
        <template v-if="isSuper || isAdmin || isSupervisor">
          <v-col>
            <v-autocomplete
              v-model="filteremployee"
              class="mx-1"
              label="Search by Employees"
              :items="workersf"
              :loading="loadingEmployees"
              :disabled="
                filterpatient != null ||
                (filternumber != null && filternumber != '')
              "
              :filter="
                (item, queryText) => {
                  return item.fullname
                    .toLowerCase()
                    .includes(queryText.toLowerCase());
                }
              "
              item-text="fullname"
              item-value="uuid"
              autocomplete="off"
              clearable
              hide-details
              outlined
              dense
            >
              <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.fullname"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-if="data.item.ocupation"
                    class="text-capitalize"
                    v-html="data.item.ocupation.name"
                  ></v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="data.item.phone"
                    class="text-capitalize"
                    v-html="data.item.phone"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </template>
        <v-col class="">
          <ma-date-picker v-model="dateFrom" label="From" past />
        </v-col>
        <v-col class="mx-2">
          <ma-date-picker v-model="dateTo" label="To" past />
        </v-col>
        <template v-if="validClean">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-2"
                elevation="0"
                small
                rounded
                depressed
                fab
                v-on="on"
                v-bind="attrs"
                @click="_clearFilters"
              >
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </template>
            <span>Clear filters</span>
          </v-tooltip>
        </template>
      </v-row>
      <v-btn
        color="primary"
        :loading="loading"
        :disabled="loading"
        text
        @click="getCalls"
      >
        <v-icon>mdi-sync</v-icon>
        {{ $t("labelUpdate") }}
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-data-table
      :headers="heads"
      :items="missedcall"
      :footer-props="footerProps"
      :options.sync="options"
      :server-items-length="totalmiss"
      item-key="index"
      :loading="loading"
    >
      {{/* From agent */}}
      <template v-slot:[`item.from`]="{ item }">
        <div class="phone-number d-flex">
          <template v-if="admin">
            <v-icon color="error" class="mr-1">mdi-phone-outgoing</v-icon>
            <div class="d-flex">
              <div class="cursor-pointer" @click="makeCallV(item.from)">
                {{ item.from }}
              </div>
              <div class="mx-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="copyPhone(item.from)"
                      v-on="on"
                      small
                      color="info"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy Phone</span>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-else>
            <v-icon color="error" class="mr-1">
              {{
                item.inbound == 1 ? "mdi-phone-incoming" : "mdi-phone-outgoing"
              }}</v-icon
            >

            <div class="d-flex">
              <div
                class="cursor-pointer"
                @click="makeCallV(item.inbound == 1 ? item.from : item.to)"
              >
                {{ item.inbound == 1 ? item.from : item.to }}
              </div>
              <div class="mx-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="
                        copyPhone(item.inbound == 1 ? item.from : item.to)
                      "
                      v-on="on"
                      small
                      color="info"
                      >mdi-content-copy</v-icon
                    >
                  </template>
                  <span>Copy Phone</span>
                </v-tooltip>
              </div>
            </div>
          </template>
        </div>
      </template>
      {{/* To agent */}}
      <template v-slot:[`item.to`]="{ item }">
        <div class="phone-number d-flex">
          <v-icon color="error" class="mr-1">mdi-phone-incoming</v-icon>
          <div class="d-flex">
            <div class="cursor-pointer" @click="makeCallV(item.to)">
              {{ item.to }}
            </div>
            <div class="mx-1">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="copyPhone(item.to)"
                    v-on="on"
                    small
                    color="info"
                    >mdi-content-copy</v-icon
                  >
                </template>
                <span>Copy Phone</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>

      {{/* Duration */}}
      <template v-slot:[`item.duration`]="{ item }">
        <div class="text-body-2">{{ prettyTime(item.duration) }}</div>
      </template>
      {{/* Origin */}}
      <template v-slot:[`item.origin`]="{ item }">
        <template v-if="admin">
          <div class="text-body-2">{{ getFromCall(item).origin }}</div>
        </template>
        <template v-else>
          <div class="text-body-2">
            {{
              getFromCall(item).origin == "Employee"
                ? "MySelf"
                : getFromCall(item).origin
            }}
          </div>
        </template>
      </template>
      {{/* Name */}}
      <template v-slot:[`item.name`]="{ item }">
        <template v-if="admin">
          <div class="text-body-2">
            {{
              getFromCall(item).name == getName
                ? "MySelf"
                : getFromCall(item).name
            }}
          </div>
        </template>
        <template v-else>
          <div class="text-body-2">{{ getFromCall(item).name }}</div>
        </template>
      </template>

      <template v-slot:[`item.toname`]="{ item }">
        <div class="text-body-2">{{ getNameTo(item) }}</div>
      </template>
      {{/* Date */}}
      <template v-slot:[`item.createAt`]="{ item }">
        <div class="text-body-2">{{ formatDate(item.createAt) }}</div>
      </template>

      {{/* Remove record */}}

      <template v-slot:[`item.actions`]="{ item }">
        <template v-if="isSuper">
          <v-btn color="red" icon @click="setRecordToRemove(item)">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
          <!-- <template v-if="item.voicemail[0] != null">
            <v-btn icon color="warning" @click="playRecord(item.voicemail[0])">
              <v-icon>mdi-play-circle-outline</v-icon></v-btn
            >
          </template> -->
        </template>
        <template v-else>
          <!-- <template>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" icon @click="makeCall(item)">
                  <v-icon>mdi-phone-forward</v-icon>
                </v-btn>
              </template>
              <span>Make Call</span>
            </v-tooltip>
          </template> -->
          <!--  <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="setContact(item)"
                v-bind="attrs"
                v-on="on"
                text
                color="info"
                ><v-icon>mdi-message-text-outline</v-icon></v-btn
              >
            </template>
            <span>Send SMS</span>
          </v-tooltip> -->
          <!-- <template v-if="item.voicemail[0] != null">
            <v-btn icon color="warning" @click="playRecord(item.voicemail[0])">
              <v-icon>mdi-play-circle-outline</v-icon></v-btn
            >
          </template> -->
        </template>
      </template>

      <not-records slot="no-data" />
    </v-data-table>

    {{/* Confirm remove */}}
    <v-dialog
      v-model="dialogConfirmRemove"
      max-width="280"
      content-class="rounded-xl"
      :persistent="loading"
    >
      <v-card>
        <v-card-title class="text-h6 font-weight-regular rounded-xl">
          {{ $t("dialogTitle") }}
        </v-card-title>
        <v-layout class="pa-4" column>
          <div class="text-body-1 text-truncate d-flex mb-2">
            <v-icon color="green" class="mr-1">mdi-phone-outgoing</v-icon>
            <span class="dosis">{{ currentRecord.from }}</span>
          </div>
          <div class="text-body-1 text-truncate d-flex">
            <v-icon color="green" class="mr-1">mdi-phone-incoming</v-icon>
            <span class="dosis">{{ cleanPhone(currentRecord.to) }}</span>
          </div>
        </v-layout>
        <v-divider />
        <v-card-actions class="justify-end">
          <v-btn
            color="success"
            :disabled="loading"
            @click="dialogConfirmRemove = false"
            text
          >
            {{ $t("labelCancel") }}
          </v-btn>
          <v-btn color="red" :loading="loading" @click="remove" text>
            {{ $t("labelRemove") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/**
 * Show missed calls
 */
import Vue from "vue";
import { CallList } from "@/mixins";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import rules from "@/components/account/rules";
import NotRecords from "@/components/NotRecords.vue";
import RecordedCall from "@/models/RecordedCall";
import BtnMakeCall from "./buttons/BtnMakeCall.vue";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import moment from "moment";
import RecordPlayer from "./RecordPlayer.vue";
import { notifyInfo } from "@/components/Notification";
import { getAPI } from "@/api/axios-base";
import { funtionsPhone } from "@/utils/mixins";
// import TableToolbar from "./TableToolbar.vue";
import FilterByClients from "@/components/filters/FilterByClients.vue";
export default Vue.extend({
  // components: { TableToolbar },
  name: "missed-calls",
  mixins: [CallList, funtionsPhone],
  components: {
    NotRecords,
    BtnMakeCall,
    MaDatePicker,
    RecordPlayer,
    FilterByClients,
  },
  data() {
    return {
      rules: {
        email: rules.email,
        required: rules.required,
        phone: rules.isPhone,
      },
      showPlayer: false,
      file: "",
      currentRecord: {
        uuid: "66efaa8b-7d8c-4a44-8d94-24aede53f2f7",
        from: "+111111111",
        to: "+122222222",
      },
      dialogConfirmRemove: false,
      footerProps: { "items-per-page-options": [15, 50, 100, 1000] },
      options: {},
      filterRange: "year",
      filternumber: null,
      filterpatient: null,
      filteremployee: null,
      onlyWithCheck: false,
      reqbody: {
        range: {
          limit: 15,
          offset: 0,
        },
        missed: true,
        dateRange: {
          date1: "",
          date2: "",
        },
        patientUuid: null,
        phoneNumber: null,
        employeeUuid: null,
      },
      dateFrom: "",
      dateTo: "",
      headers: [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("Name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("crmCallCenter.from"),
          align: "start",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("Name"),
          align: "start",
          sortable: false,
          value: "toname",
        },
        {
          text: this.$t("crmCallCenter.to"),
          align: "start",
          sortable: false,
          value: "to",
        },
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getCalls();
      }
    },
    dateFrom(val) {
      if (val !== null && this.dateTo !== null && this.dateTo !== "") {
        this.filterRange = "custom";
        this.getCalls();
      }
    },
    dateTo(val) {
      if (val !== null && this.dateFrom !== null && this.dateFrom !== "") {
        this.filterRange = "custom";
        this.getCalls();
      }
    },

    filternumber(val) {
      if (val != null) {
        this.filterpatient = null;
        this.filteremployee = null;
        if (val.length == 10) {
          this.reqbody.phoneNumber = "+1" + val;
          this.getCalls();
        } else if (val.length == 0) {
          this.reqbody.phoneNumber = null;
          this.getCalls();
        }
      }
    },

    filteremployee(val) {
      if (val != null) {
        this.filterpatient = null;
        this.filternumber = null;
        this.reqbody.phoneNumber = null;
        this.reqbody.patientUuid = null;
        this.reqbody.employeeUuid = val;
        this.getCalls();
      } else {
        this.reqbody.phoneNumber = null;
        this.reqbody.employeeUuid = null;
        this.getCalls();
      }
    },

    onlyWithCheck: {
      handler() {
        this.getCalls();
      },
    },
  },
  destroyed() {
    this.mutRequest(null);
    this.mutFilter(false);
  },
  computed: {
    ...mapGetters([
      "isSuper",
      "isAdmin",
      "getPhone",
      "isSupervisor",
      "getUser",
      "getName",
      "getpatientN",
    ]),
    ...mapState("crmEmployeeModule", {
      loadingEmployees: "loading",
      employees: "workers",
    }),

    ...mapState(["totalitems", "userContacts"]),
    ...mapState("crmCallCenterModule", [
      "missList",
      "totalmiss",
      "requestC",
      "filter",
    ]),

    workersf() {
      const w = this.employees.filter((e) => e.phone != null && e.phone != "");
      return w;
    },
    validClean() {
      if (
        (this.dateFrom != null &&
          this.dateFrom != undefined &&
          this.dateFrom != "") ||
        (this.dateTo != null && this.dateTo != undefined && this.dateTo != "")
      ) {
        return true;
      }
      return false;
    },

    admin() {
      if (this.isSuper || this.isAdmin || this.isSupervisor) {
        return true;
      }
      return false;
    },
    missedcall() {
      return this.missList;
    },

    heads() {
      const temp = [
        {
          text: this.$t("origin"),
          align: "start",
          sortable: false,
          value: "origin",
        },
        {
          text: this.$t("name"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Number",
          align: "start",
          sortable: false,
          value: "from",
        },
        {
          text: this.$t("crmCallCenter.duration"),
          align: "left",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("labelDate"),
          align: "center",
          sortable: false,
          value: "createAt",
        },
        { text: "", value: "actions", sortable: false },
      ];
      return this.admin ? this.headers : temp;
    },
  },
  mounted() {
    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      this.actGetEmployees();
    }
  },

  methods: {
    ...mapActions("crmCallCenterModule", [
      "actGetCallsMis",
      "actRemoveRecordedCall",
    ]),
    ...mapMutations("crmCallCenterModule", [
      "mutRequest",
      "mutFilter",
      "mutView",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),

    ...mapMutations("crmSMSModule", ["mutSetContact"]),
    ...mapMutations(["mutReqRange"]),
    makeCall(item) {
      const number = item.inbound == 1 ? item.from : item.to;

      getAPI("/zoom/zoomPhonecall", {
        params: {
          phone: number,
        },
      }).then((res) => {
        window.open(res.data, "_blank", "noreferrer");
      });
    },
    filteringPatient(val) {
      if (val != null) {
        this.filternumber = null;
        this.filteremployee = null;
        localStorage.removeItem("uuidemployee");
        this.reqbody.patientUuid = val;
        this.getCalls();
      } else {
        this.reqbody.patientUuid = null;
        this.getCalls();
      }
    },
    getNameTo(item) {
      if (item.to == `client:${item.employee_username}`) {
        return item.employee_fullname;
      }
      if (item.to == item.employee_phone) {
        return item.employee_fullname;
      }
      if (item.patient_phone == item.to) {
        return item.patient_name;
      }
      if (item.l_phone == item.to) {
        return item.l_name;
      }
      return "-";
    },

    closedPlayer() {
      this.file = "";
      this.showPlayer = false;
    },
    playRecord(file) {
      this.file = file.recordUrl;
      this.showPlayer = true;
    },
    itemStatus(call) {
      if (call.status == "completed") {
        return "mdi-phone-check";
      } else if (call.status == "queued") {
        return "mdi-phone-settings";
      } else if (call.status == "ringing") {
        return "mdi-phone-in-talk";
      } else if (call.status == "canceled") {
        return "mdi-phone-missed";
      } else {
        if (
          call.from == this.getPhone ||
          call.from == `client:${this.getUser}`
        ) {
          return "mdi-phone-forward-outline";
        }
        return "mdi-phone-missed";
      }
    },
    numberShow(call) {
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return call.to;
      } else {
        return call.from;
      }
    },

    _origin(item) {
      if (item.from == `client:${item.employee_username}`) {
        return "Employee";
      }

      if (item.from == item.employee_phone) {
        return "Employee";
      }
      if (item.patient_phone == item.from) {
        return "Patient";
      }
      if (item.l_phone == item.from) {
        return "Lead";
      }

      return "-";
    },

    getFrom(call) {
      if (call.from == null || call.from == undefined) {
        return "-";
      }
      if (call.from == this.getPhone || call.from == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return this.cleanPhone(call.from, call);
      }
    },
    getTo(call) {
      if (call.to == null || call.to == undefined) {
        return "-";
      }
      if (call.to == this.getPhone || call.to == `client:${this.getUser}`) {
        return "My selft";
      } else {
        return this.cleanPhone(call.to, call);
      }
    },
    _name(item) {
      if (item.from == `client:${item.employee_username}`) {
        return item.employee_fullname;
      }

      if (item.from == item.employee_phone) {
        return item.employee_fullname;
      }
      if (item.patient_phone == item.from) {
        return item.patient_name;
      }
      if (item.l_phone == item.from) {
        return item.l_name;
      }

      return "-";
    },

    setContact(item) {
      const phone = this.numberShow(item);
      const contact = { number: phone, name: phone };
      this.mutSetContact(contact);
      this.$router.push("/chat");
    },

    getCalls() {
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != -1 ? itemsPerPage : 15;
      this.reqbody.range = {
        limit: itmper,
        offset: (page - 1) * itmper,
      };
      if (this.filterRange == "custom") {
        this.reqbody.dateRange = {
          date1: moment(this.dateFrom).startOf("day").utc().toISOString(),
          date2: moment(this.dateTo).endOf("day").utc().toISOString(),
        };
      }

      let body = Object.assign({}, this.reqbody);
      if (this.filteremployee != null) {
        delete body.phoneNumber;
      } else {
        delete body.employeeUuid;
      }

      body = { ...body, onlyWithCheck: this.onlyWithCheck };
      if (this.filterRange != "custom") {
        delete body.dateRange;
      }

      this.actGetCallsMis(body);
    },

    setRecordToRemove(record) {
      this.currentRecord = record;
      this.dialogConfirmRemove = true;
    },
    remove() {
      this.actRemoveRecordedCall(this.currentRecord.uuid).then(() => {
        this.dialogConfirmRemove = false;
        this.getCalls();
        const en = this.$i18n.locale == "en";
        const bodyEn = "Call deleted successfully";
        const bodyEs = "Llamada eliminada con éxito";

        notifyInfo(en ? bodyEn : bodyEs);
      });
    },
    _clearFilters() {
      this.dateFrom = "";
      this.dateTo = "";
      this.filterRange = "year";
      this.clearFilters();
    },
    clearFilters() {
      this.filternumber = null;
      this.filterpatient = null;
      this.filteremployee = null;
      this.reqbody.phoneNumber = null;
      this.reqbody.employeeUuid = null;
      this.getCalls();
    },
  },
});
</script>

<i18n>
{
  "en": {
    "dialogTitle": "Delete call?",
    "missed": "Missed",
    "successful": "Successful",
    "origin":"Origin"
  },
  "es": {
    "dialogTitle": "¿Eliminar llamada??",
    "missed": "Perdida",
    "successful": "Correcta",
    "origin":"Origen"
  }
}
</i18n>
